.input-control {
  label {
    transform: translate(0, 40%);
    font-size: 1.125rem;
  }

  input::placeholder {
    color: transparent;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    transform: translate(0, 0%);
    font-size: 0.95rem;
  }
}
